// src/components/Navbar.jsx
import React, { useState, useEffect } from 'react';
import { Link } from 'react-scroll';

const Navigation = () => {
  const [scrolled, setScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const navItems = [
    { id: 'introduction', label: 'HOME' },
    { id: 'aboutUs', label: 'ABOUT US' },
    { id: 'services', label: 'SERVICES' },
    { id: 'contactUs', label: 'CONTACT US' }
  ];

  return (
    <nav className={`fixed top-0 w-full z-50 transition-all duration-300 ${
      scrolled ? 'bg-gray-900 shadow-lg' : 'bg-white'
    }`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 items-center">
          {/* Logo */}
          <div className="flex-shrink-0 flex items-center gap-3">
            <img 
              src='/assets/images/logo.png' 
              alt="Dubai Smile Dental Laboratory Logo" 
              className={`h-8 w-auto transition-all duration-300 ${
                scrolled ? 'filter brightness-0 invert' : 'filter brightness-100'
              }`}
              style={{ objectFit: 'contain' }}
            />
            <div className="flex flex-col">
              <span className={`text-xl font-bold leading-tight ${scrolled ? 'text-white' : 'text-gray-900'}`}>
                <span className="text-blue-600">Dubai Smile</span>
              </span>
              <span className={`text-sm font-medium ${scrolled ? 'text-gray-300' : 'text-gray-600'}`}>
                Dental Laboratory
              </span>
            </div>
          </div>

          {/* Mobile menu button */}
          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className={`inline-flex items-center justify-center p-2 rounded-md ${
                scrolled ? 'text-white' : 'text-gray-900'
              } hover:bg-gray-100 focus:outline-none`}
            >
              <svg
                className="h-6 w-6"
                stroke="currentColor"
                fill="none"
                viewBox="0 0 24 24"
              >
                {isOpen ? (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                ) : (
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                )}
              </svg>
            </button>
          </div>

          {/* Desktop menu */}
          <div className="hidden md:flex md:items-center md:space-x-8">
            {navItems.map((item) => (
              <Link
                key={item.id}
                to={item.id}
                smooth={true}
                duration={500}
                spy={true}
                offset={-64}
                className={`cursor-pointer ${
                  scrolled ? 'text-white' : 'text-gray-900'
                } hover:text-blue-600 transition-colors duration-200`}
              >
                {item.label}
              </Link>
            ))}
            <a
              href="/login"
              target="_blank"
              rel="noopener noreferrer"
              className={`px-4 py-2 rounded-md ${
                scrolled
                  ? 'bg-white text-gray-900 hover:bg-gray-100'
                  : 'bg-gray-900 text-white hover:bg-gray-800'
              } transition-colors duration-200`}
            >
              Dentist Login Portal
            </a>
          </div>
        </div>

        {/* Mobile menu */}
        <div className={`md:hidden ${isOpen ? 'block' : 'hidden'}`}>
          <div className={`px-2 pt-2 pb-3 space-y-1 ${scrolled ? 'bg-gray-900' : 'bg-white'}`}>
            {navItems.map((item) => (
              <Link
                key={item.id}
                to={item.id}
                smooth={true}
                duration={500}
                spy={true}
                offset={-64}
                className={`block px-3 py-2 rounded-md text-base font-medium ${
                  scrolled ? 'text-white' : 'text-gray-900'
                } hover:text-blue-600 transition-colors duration-200`}
                onClick={() => setIsOpen(false)}
              >
                {item.label}
              </Link>
            ))}
            <a
              href="/login"
              target="_blank"
              rel="noopener noreferrer"
              className={`block px-3 py-2 rounded-md text-base font-medium ${
                scrolled
                  ? 'bg-white text-gray-900 hover:bg-gray-100'
                  : 'bg-gray-900 text-white hover:bg-gray-800'
              } transition-colors duration-200`}
            >
              3D Viewer
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navigation;
