// src/components/ContactUs.tsx
import React from 'react';
import { FaInstagram, FaEnvelope } from 'react-icons/fa';

const ContactUs = () => {
  return (
    <section id="contactUs" className="py-16 bg-blue-900 text-white">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12 relative pb-4">
          Contact Us
          <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-20 h-1 bg-blue-400"></span>
        </h2>

        <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Instagram */}
          <div className="text-center">
            <FaInstagram className="text-4xl text-blue-400 mx-auto mb-4" />
            <p className="text-lg mb-2">Follow us on Instagram</p>
            <a 
              href="https://www.instagram.com/dubaismiledl?igsh=ZGNydmg3NHBrNmJn" 
              target="_blank" 
              rel="noopener noreferrer"
              className="text-blue-300 hover:text-blue-400 transition-colors duration-300"
            >
              @dubaismiledl
            </a>
          </div>

          {/* Email */}
          <div className="text-center">
            <FaEnvelope className="text-4xl text-blue-400 mx-auto mb-4" />
            <p className="text-lg mb-2">Infodubaismiledl@gmail.com</p>
            <a 
              href="mailto:Infodubaismiledl@gmail.com"
              className="text-blue-300 hover:text-blue-400 transition-colors duration-300"
            >
              Email us
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
