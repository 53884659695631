import { useMediaQuery } from "react-responsive";

import { useModel } from "../context";
import ZoomButtons from "./buttons/zoom-buttons";
import OrientationControls from "./controls/orientation-controls";
import JawsControls from "./controls/jaws-controls";
import ShareButton from "./buttons/share-button";
import DEVICE_SIZES from "../constants/device-sizes";
import { useParams } from "react-router-dom";

const Header = () => {
  const { logoImg } = useParams();
  const decodedLogoImg = decodeURIComponent(logoImg);
  const { model } = useModel();
  const isMinPhone = useMediaQuery({ minWidth: DEVICE_SIZES.phone });

  return (
    <div className="flex items-start select-none">
      {model.isLoading && (
        <div className="bg-white/10 backdrop-blur-sm h-36 absolute top-0 left-0 right-0 bottom-0 z-10" />
      )}
      <div className="absolute border border-gray-100 bg-gray-50 left-0 w-40 h-20 flex items-center justify-center rounded-lg overflow-hidden">
        <img
          src={decodedLogoImg}
          alt="Logo"
          class="w-[9rem] cursor-pointer h-[4rem] object-cover"
        />
      </div>

      {isMinPhone ? <ZoomButtons /> : null}
      <div className="flex-grow">
        <OrientationControls />
        <JawsControls />
      </div>
      <ShareButton />
    </div>
  );
};

export default Header;
