import { useEffect, useState, useMemo, useCallback } from "react";

import { Color, Box3, Vector3 } from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { MTLLoader } from "three/examples/jsm/loaders/MTLLoader";
import { useProgress } from "@react-three/drei";

import CanvasLoader from "./canvas/canvas-loader";
import MODELS from "../constants/models";
import RenderIprPositions from "./ipr-positions";
import { useModel } from "../context";

const JawModel = (props) => {
  const { upperJawModel, lowerJawModel, iprModel, isUpperJaw, isLowerJaw, isAttachment, isIpr, isElastic } = props;
  const [upperJawObject, setUpperJawObject] = useState(null);
  const [lowerJawObject, setLowerJawObject] = useState(null);
  const [upperJawIPRPositions, setUpperJawIPRPositions] = useState([]);
  const [lowerJawIPRPositions, setLowerJawIPRPositions] = useState([]);
  const { progress } = useProgress();
  const { model, setModel } = useModel();

  const dataModel = useMemo(() => MODELS.at(-1), []);

  const loadModel = useCallback(
    (mtlPath, objPath, iprData, setModel, setIPRPositions, isUpper) => {
      const mtlLoader = new MTLLoader();
      const objLoader = new OBJLoader();

      mtlLoader.load(mtlPath, (materials) => {
        materials.preload();

        const mandibularMaterial = materials.materials.Mandibular;
        if (mandibularMaterial) {
          const [r, g, b] = [0.8, 0.3, 0.3];
          mandibularMaterial.color.setRGB(r, g, b);
          mandibularMaterial.needsUpdate = true;
        }

        const maxillaryMaterial = materials.materials.Maxillary;
        if (maxillaryMaterial) {
          const [r, g, b] = [0.8, 0.3, 0.3];
          maxillaryMaterial.color.setRGB(r, g, b);
          maxillaryMaterial.needsUpdate = true;
        }

        objLoader.setMaterials(materials).load(objPath, (object) => {
          // console.log(object);
          const positions = [];
          const iprLines = [];

          object.traverse((child) => {
            if (child.isMesh && child.material) {
              child.material.shininess = 50;
              child.material.specular = new Color("whitesmoke");
              child.material.needsUpdate = true;

              if (child.material.name.startsWith("Tooth_")) {
                const boundingBox = new Box3().setFromObject(child);
                const center = boundingBox.getCenter(new Vector3());
                const size = boundingBox.getSize(new Vector3());
                const direction = center.clone().normalize();
                const outwardDistance = size.length() * 1.5 + 10;

                direction.setY(
                  isUpper ? Math.abs(direction.y) : -Math.abs(direction.y)
                );

                const textPosition = center
                  .clone()
                  .add(direction.multiplyScalar(outwardDistance));

                const spacingFactor = 10;
                const adjustedTextPosition = textPosition
                  .clone()
                  .add(direction.multiplyScalar(spacingFactor));

                positions.push({
                  name: child.material.name,
                  position: adjustedTextPosition,
                  toothCenter: center.clone(),
                });
              }
            }
          });

          iprData?.forEach((ipr) => {
            const toothOnePosition = positions.find(
              (t) => t.name === ipr.tooth_one
            );
            const toothTwoPosition = positions.find(
              (t) => t.name === ipr.tooth_two
            );

            if (!toothOnePosition || !toothTwoPosition) {
              return;
            }

            const midpoint = new Vector3()
              .addVectors(
                toothOnePosition.toothCenter,
                toothTwoPosition.toothCenter
              )
              .multiplyScalar(0.5);
            const size = toothOnePosition.toothCenter.distanceTo(
              toothTwoPosition.toothCenter
            );
            const direction = midpoint.clone().normalize();
            const outwardDistance = size * 1.5 + 10;

            direction.setY(
              isUpper ? Math.abs(direction.y) : -Math.abs(direction.y)
            );

            const textPosition = midpoint
              .clone()
              .add(direction.multiplyScalar(outwardDistance));

            iprLines.push({
              name: `Mid_${ipr.tooth_one}_${ipr.tooth_two}`,
              position: textPosition,
              toothCenter: midpoint.clone(),
              aligner: ipr.aligner,
              stripping: ipr.stripping,
            });
          });

          setModel(object);
          setIPRPositions(iprLines);
        });
      });
    },
    []
  );

  useEffect(() => {
    loadModel(
      dataModel.upperJaw.mtl,
      upperJawModel.obj,
      iprModel?.upperJawIpr,
      setUpperJawObject,
      setUpperJawIPRPositions,
      true
    );
    loadModel(
      dataModel.lowerJaw.mtl,
      lowerJawModel.obj,
      iprModel?.lowerJawIpr,
      setLowerJawObject,
      setLowerJawIPRPositions,
      false
    );
  }, [loadModel, dataModel, upperJawModel, lowerJawModel, iprModel]);

  useEffect(() => {
    if (!upperJawObject || !lowerJawObject) return;

    const toggleAttachments = (object) => {
      object.traverse((child) => {
        if (child.isMesh && child.material) {
          const isAttachmentMesh = child.material.name.startsWith("Attachment0");
          if (isAttachmentMesh) {
            child.visible = isAttachment;
          }

          const isElasticMesh = child.material.name.startsWith("Attachment1");
          if (isElasticMesh) {
            child.visible = isElastic;
          }
        }
      });
    };

    toggleAttachments(upperJawObject);
    toggleAttachments(lowerJawObject);

  }, [isElastic, isAttachment, upperJawObject, lowerJawObject]);

  useEffect(() => {
    if (progress < 100 && !model.isLoading)
      setModel((prev) => ({ ...prev, isLoading: true }));
  }, [progress])

  if (progress !== 100) return <CanvasLoader />;

  return (
    <mesh scale={0.1}>
      <hemisphereLight
        intensity={2}
        color={new Color("white")}
        groundColor={new Color("gray")}
        position={[10, 10, 10]}
      />
      <pointLight
        intensity={1}
        color={new Color("white")}
        position={[10, 10, 10]}
        distance={50}
        decay={2}
      />
      {isUpperJaw && upperJawObject && (
        <primitive object={upperJawObject} position={[0, 0, 0]} />
      )}
      {isLowerJaw && lowerJawObject && (
        <primitive object={lowerJawObject} position={[0, 1, 0]} />
      )}
      {isIpr && isUpperJaw && (
        <RenderIprPositions iprPositions={upperJawIPRPositions} />
      )}
      {isIpr && isLowerJaw && (
        <RenderIprPositions iprPositions={lowerJawIPRPositions} />
      )}
    </mesh>
  );
};

export default JawModel;
