import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

const JawsAligner = () => {
  const { caseId } = useParams();
  const [alignerData, setAlignerData] = useState({ upperAligner: "-", lowerAligner: "-" });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {

    const fetchAlignerData = async () => {
      try {
        const response = await axios.get(`https://dubaismiledl.com/v2api/api/aligner/get/${caseId}`);
        setAlignerData(response.data);
      } catch (err) {
        setError("Failed to fetch aligner data");
      } finally {
        setLoading(false);
      }
    };

    fetchAlignerData();
  }, [caseId]);

  return (
    <div className="flex items-center justify-center mb-3">
      <div className="flex flex-col items-center border-r border-gray-700 py-3 px-6">
        <p className="font-bold">{loading ? "..." : alignerData.upperAligner || "N/A"}</p>
        <p className="text-gray-500">Upper</p>
      </div>
      <div className="flex flex-col items-center py-3 px-6">
        <p className="font-bold">{loading ? "..." : alignerData.lowerAligner || "N/A"}</p>
        <p className="text-gray-500">Lower</p>
      </div>
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>
  );
};

export default JawsAligner;
