// src/components/Introduction.tsx
import React from 'react';
import { services } from './ServiceCards';

const Introduction = () => {
  const [currentSlide, setCurrentSlide] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % services.length);
    }, 3000);
    return () => clearInterval(timer);
  }, []);

  return (
    <section id="introduction" className="relative h-screen w-full overflow-hidden">
      {/* Background Carousel */}
      <div className="absolute inset-0">
        {services.map((service, index) => (
          <div
            key={index}
            className={`absolute inset-0 transition-opacity duration-1000 ${
              currentSlide === index ? 'opacity-100' : 'opacity-0'
            }`}
          >
            <img
              src={service.imgSrc}
              alt={service.title}
              className="w-full h-full object-cover"
            />
          </div>
        ))}
      </div>

      {/* Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-white/80 to-black/40"></div>

      {/* Content */}
      <div className="relative z-10 h-full flex items-center justify-center">
        <div className="text-center text-white px-4">
          <h2 className="text-4xl md:text-5xl lg:text-6xl font-extrabold mb-6 drop-shadow-lg">
            State of the Art Digital Dental Lab
          </h2>
          <p className="text-lg md:text-xl lg:text-2xl font-semibold drop-shadow-md max-w-3xl mx-auto">
            Our state-of-the-art digital dental lab technicians serve customers across the globe, 
            through our lab in Dubai.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Introduction;
