import React from 'react';

// Change this line to export the services array
export const services = [
  {
    title: 'Dental Implants',
    imgSrc: '/assets/images/dental-implants.jpg',
    description: 'Replace missing teeth with strong, natural-looking dental implants.',
  },
  {
    title: 'Dental Bridges',
    imgSrc: '/assets/images/dental-bridges.jpg',
    description: 'Restore your smile with high-quality dental bridges.',
  },
  {
    title: 'Dental Veneers',
    imgSrc: '/assets/images/dental-veneers.jpg',
    description: 'Enhance your smile with custom-designed dental veneers.',
  },
  {
    title: 'Dental Crowns',
    imgSrc: '/assets/images/dental-crowns.jpg',
    description: 'Protect and restore your teeth with durable dental crowns.',
  },
  {
    title: 'Dentures & Retainers',
    imgSrc: '/assets/images/dentures-retainers.jpg',
    description: 'Restore function and aesthetics with comfortable dentures and retainers.',
  },
  {
    title: 'Nightguards',
    imgSrc: '/assets/images/nightguards.jpg',
    description: 'Protect your teeth from grinding and clenching with custom nightguards.',
  },
  {
    title: 'Aligners',
    imgSrc: '/assets/images/aligners.jpg',
    description: 'Straighten your teeth with discreet and effective aligners.',
  },
];

const ServiceCards = () => {
  return (
    <div className="container mx-auto px-4">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {services.map((service, index) => (
          <div 
            key={index} 
            className="bg-white rounded-lg shadow-md overflow-hidden transition-transform duration-300 hover:scale-105"
          >
            <div className="aspect-w-16 aspect-h-9">
              <img
                src={service.imgSrc}
                alt={service.title}
                className="w-full h-48 object-cover"
              />
            </div>
            <div className="p-4 text-center">
              <h3 className="text-xl font-semibold text-gray-800 mb-2">
                {service.title}
              </h3>
              <p className="text-gray-600 text-sm">
                {service.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceCards;
