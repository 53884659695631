// src/components/AboutUs.jsx
import React from 'react';
import { FaTooth, FaUserMd, FaGlobe, FaClipboardCheck, FaCog, FaHandshake, FaRocket } from 'react-icons/fa';

const AboutUs = () => {
  const features = [
    { icon: <FaTooth className="text-4xl text-blue-600" />, text: "State-of-the-art digital dental lab" },
    { icon: <FaUserMd className="text-4xl text-blue-600" />, text: "Experienced dental technicians" },
    { icon: <FaGlobe className="text-4xl text-blue-600" />, text: "Serving customers globally" },
    { icon: <FaClipboardCheck className="text-4xl text-blue-600" />, text: "Quality control measures" },
    { icon: <FaCog className="text-4xl text-blue-600" />, text: "Advanced CAD/CAM technology" },
    { icon: <FaHandshake className="text-4xl text-blue-600" />, text: "Personalized customer service" },
    { icon: <FaRocket className="text-4xl text-blue-600" />, text: "Fast turnaround times" },
  ];

  return (
    <section id="aboutUs" className="py-16 bg-gray-50">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-gray-800 text-center mb-8 relative pb-4">
          About Us
          <span className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-20 h-1 bg-blue-600"></span>
        </h2>

        <div className="max-w-4xl mx-auto text-center mb-12 space-y-6">
          <p className="text-lg text-gray-700">
            At DentalLab, we specialize in cutting-edge digital dentistry techniques, 
            offering precise and efficient solutions for a wide range of dental needs.
            Our state-of-the-art facility is equipped with the latest technology, 
            enabling us to deliver exceptional results for every client.
          </p>
          <p className="text-lg text-gray-700">
            Our team of experienced dental technicians brings years of expertise to every project. 
            We craft high-quality, custom dental prosthetics tailored to each patient's unique requirements, 
            ensuring optimal fit, function, and aesthetics.
          </p>
          <p className="text-lg text-gray-700">
            We serve dental professionals across the globe, providing fast, reliable, and 
            top-quality dental laboratory services. Our dedication to customer satisfaction, 
            combined with our advanced capabilities, makes us the preferred choice for 
            dentists who demand the very best for their patients.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mt-12">
          {features.map((feature, index) => (
            <div 
              key={index}
              className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300 text-center"
            >
              <div className="mb-4">{feature.icon}</div>
              <p className="text-gray-700 font-medium">{feature.text}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
