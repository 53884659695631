import React from "react";

import { toast } from "react-toastify";

import { useModel } from "../../context";
import { TOASTIFY_OPTIONS } from "../../constants/toastify";
import { FaDownload, FaRegShareFromSquare } from "react-icons/fa6";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

const ShareButton = () => {
  const { model } = useModel();
  const { caseId } = useParams();

  const handleShareClick = async () => {
    const queryParams = new URLSearchParams({
      zoom: model.zoom,
      orientation: JSON.stringify(model.orientation),
      jaws: JSON.stringify(model.jaws),
      additional: JSON.stringify(model.additional),
      displayedTeethIndex: model.displayedTeethIndex,
    });

    const shareableUrl = `${window.location.origin}${window.location.pathname}/?${queryParams.toString()}`;

    try {
      await navigator.clipboard.writeText(shareableUrl);
      toast.success("Link copied to clipboard!", TOASTIFY_OPTIONS);
    } catch (error) {
      toast.error("Failed to copy the link to clipboard!", TOASTIFY_OPTIONS);
    }
  };
  const handleDownloadPDFClick = async () => {
    try {
      // Fetch the PDF URL using the new API
      const response = await fetch(`https://dubaismiledl.com/v2api/api/files/pdf/${caseId}`);

      if (!response.ok) {
        alert("No PDF file found for this case.");
        return;
      }

      const data = await response.json();

      // Ensure the API response contains a valid URL
      if (!data.downloadUrl) {
        alert("Invalid PDF file URL.");
        return;
      }

      // Open the PDF in a new tab
      window.open(data.downloadUrl, "_blank");
    } catch (error) {
      console.error("Error opening PDF:", error);
      alert("Failed to open the PDF file.");
    }
  };



  return (
    <div className="absolute right-0 flex flex-col gap-3 m-5">
      <div
        className="bg-gray-50 border shadow cursor-pointer rounded-md active:ring ring-offset-2 ring-gray-200 p-2 hidden lg:flex items-center gap-2 justify-center"
        onClick={handleShareClick}
      >
        <FaRegShareFromSquare />
        <p>Share</p>
      </div>

      <div
        className="bg-gray-50 border shadow cursor-pointer rounded-md active:ring ring-offset-2 ring-gray-200 p-2 hidden lg:flex items-center gap-2"
        onClick={handleDownloadPDFClick}
      >
        <FaDownload />
        <p>Download IPR</p>
      </div>
    </div>
  );
};

export default ShareButton;
