import React from 'react'
import LandingPage from '../LandingPage/landingpage'
const Landing = () => {
  return (
    <div>
        <LandingPage/>
    </div>
  )
}

export default Landing