import React from 'react';
import Navigation from './components/Navbar';
import Introduction from './components/Introduction';
import Services from './components/Services';
import AboutUs from './components/AboutUs';
import ContactUs from './components/ContactUs';

const LandingPage = () => {
  return (
    <>
      <Navigation />
      <div style={{ marginTop: '80px' }}>
        <Introduction />
        <AboutUs />
        <Services />
        <ContactUs />
      </div>
    </>
  );
};

export default LandingPage;